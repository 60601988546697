<template>
  <div class="wrap-panel">
    <div class="title">产品授权已到期，请重新激活</div>
    <el-form ref="ActiveForm" label-position="left" label-width="90px" class="demo-ruleForm" size="small">
      <el-form-item label="产品授权">
        <el-upload class="upload-btn" accept :http-request="licenseUpload" :before-upload="beforeAvatarUpload" multiple :show-file-list="false" action="void">
          <span v-text="uploadFileName || '文件选择框'"></span>
        </el-upload>
      </el-form-item>
      <el-form-item label="授权企业">
        <el-input v-model="ActiveForm.corporation" placeholder="不可编辑" disabled></el-input>
      </el-form-item>
      <el-form-item label="失效时间">
        <el-input placeholder="不可编辑" :value="ActiveForm.notValidAfter ? formateTime(ActiveForm.notValidAfter) : ''" disabled></el-input>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input :value="userInfo.username" disabled />
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input :value="userInfo.email" disabled />
      </el-form-item>
      <el-form-item label="手机号">
        <el-input :value="userInfo.mobile" disabled />
      </el-form-item>
      <div class="register-block">
        <el-button size="small" class="register-btn" type="primary" @click="submitForm('ActiveForm')">注册</el-button>
        <div class="direct-msg">
          <el-link :underline="false" type="primary" class="link-tag" @click="() => $router.push('/access/login')">有账号，直接登录</el-link>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios'
import { formateTime } from '../../util/'

export default {
  data() {
    return {
      labelPosition: 'left', // 表单标签左对齐
      see: true,
      uploadFileName: '',
      ActiveForm: {
        licenseId: '',
        corporation: '',
        notValidAfter: '',
      },
    }
  },
  computed: {
    userInfo() {
      return this.$route.query
    },
  },
  methods: {
    formateTime: formateTime,

    UploadUrl: function() {
      return '/api/license/check/'
    },

    submitForm(formName) {
      console.log(formName)
      if (this.ActiveForm.licenseId == '') {
        this.$notify({
          title: '提示',
          message: '请先上传产品许可证校验',
          duration: 3000,
        })
        return
      }
      axios
        .post(
          '/api/activate/',
          // 携带的参数
          {
            license_id: this.ActiveForm.licenseId,
            client_name: this.ActiveForm.corporation,
            username: this.userInfo.username,
            email: this.userInfo.email,
            mobile: this.userInfo.mobile_phone,
          },
        )
        .then(() => {
          this.$notify({
            title: '提示',
            message: '激活成功,请登录',
            duration: 3000,
          })
          this.$router.push({ name: 'Login' }) // 激活成功之后跳转到登录页面
        })
    },

    /** 文件上传 */
    licenseUpload(request) {
      // 先清空之前上传的信息
      this.ActiveForm.licenseId = ''
      this.ActiveForm.notValidAfter = ''
      this.ActiveForm.corporation = ''

      const formData = new FormData()
      formData.append('file', request.file)
      this.uploadFileName = request.file.name // 在上传组件中显示文件名

      axios
        .post('/api/license/check/', formData, {
          withCredentials: true,
        })
        .then((response) => {
          //上传成功
          this.ActiveForm.licenseId = response.data.data.license_id
          this.ActiveForm.notValidAfter = response.data.data.not_valid_after
          this.ActiveForm.corporation = response.data.data.client_name
          this.$notify({
            title: '提示',
            message: '校验通过',
            duration: 1000,
          })
        })
    },
    beforeAvatarUpload(file) {
      //文件上传之前
      //先清空上次上传的内容
      this.ActiveForm.licenseId = ''
      this.ActiveForm.notValidAfter = ''
      this.ActiveForm.corporation = ''

      this.uploadFileName = file.name // 显示文件名
    },
  },
}
</script>

<style scoped>
.wrap-panel {
  width: 416px;
  background: #ffffff;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 58px 28px;
  box-sizing: border-box;
}
.title {
  font-size: 18px;
  color: #c55252;
  text-align: center;
  margin-bottom: 20px;
}

.full-btn {
  width: 100%;
  height: 32px;
  opacity: 0.9;
  background: #0b71bb;
  border-radius: 4px;
}
.find-password {
  float: right;
  margin-top: 9px;
  margin-bottom: 21px;
}
.register {
  color: #002f00;
}
.link-tag {
  color: #0b71bb;
  font-size: 12px;
}
.full-input {
  display: block;
  margin-top: 28px;
}
.full-input ::v-deep .el-input__inner {
  width: 100%;
}
.register-btn {
  width: 99px;
  margin-left: 90px;
  margin-right: 16px;
}
.register-block {
  display: flex;
  align-items: flex-end;
  margin-top: 22px;
}
.direct-msg {
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #bfbfbf;
  letter-spacing: 0;
  text-align: right;
}
.upload-btn {
  width: 100%;
  height: 32px;
  line-height: 32px;
  background: #fbfbfb;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #bfbfbf;
  letter-spacing: 0;
  text-align: center;
  box-sizing: border-box;
}
::v-deep .el-upload--text {
  width: 100%;
}
::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 12px;
}
::v-deep .el-form-item__label {
  color: #666;
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #ffffff;
  border-color: #e9e9e9;
  color: #bfbfbf;
}
</style>
