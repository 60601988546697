<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
body {
  margin: 0;
  font-family: PingFangSC-Regular;
}
.time-font {
  font-family: Monaco !important;
  font-size: 12px;
}
.tag-common {
  display: inline-block;
  background: #4093e0;
  border-radius: 2px;
  padding: 0 8px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #fff;
}
.tag-danger {
  background: #c55252;
  border-radius: 2px;
  padding: 0 8px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #fff;
}
.tag-success {
  background: #35b053;
  border-radius: 2px;
  padding: 0 8px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #fff;
}
.tag-warning {
  background: #f0b45c;
  border-radius: 2px;
  padding: 0 8px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #fff;
}

.option-icon {
  font-size: 16px;
}
.option-icon:not(:last-child) {
  margin-right: 10px;
}
.table-list {
  width: 100%;
  min-width: 1050px;
  font-size: 12px;
}
.main-page > .main-head {
  line-height: 60px;
  background-color: unset !important;
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.el-table__footer-wrapper,
.el-table__header-wrapper {
  border-radius: 4px 4px 0px 0;
}

.table-box .el-table .el-table__header-wrapper {
  overflow: visible !important;;
}
.el-table th {
  color: #424242;
}
.el-table td {
  color: #666;
}
.el-header {
  height: 56px !important;
}
.el-button--mini.is-circle {
  background: #f3f3f3;
}
#app .el-table th {
  color: #333;
}
#app .el-button--primary {
  background-color: #0b71bb;
  border-color: #0b71bb;
}
#app .el-table--mini td,
#app .el-table--mini th {
  padding: 0px 0;
}
#app .el-table tr {
  height: 36px;
}
#app .el-table .cell {
  line-height: 14px !important;
}
#app .el-button--mini.is-circle {
  padding: 4px;
}
#app .el-table .sort-caret.ascending {
  top: 9px;
}
#app .el-table .sort-caret.descending {
  bottom: 11px;
}
#app .el-table .sort-caret {
  border-width: 3px;
}
#app .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: unset;
}
#app .el-link.el-link--primary {
  color: #0b71bb;
  font-weight: 400;
}
#app .el-link.el-link--primary :hover {
  color: rgb(7, 82, 137);
}
#app .el-link.el-link--primary :active {
  color: rgba(7, 82, 137, 0.8);
}
#app .el-button--mini,
#app .el-button--mini.is-round {
  padding: 5px 15px;
}
#app .chaitin-iconfont {
  font-size: 12px;
}
#app .el-input--mini .el-input__inner {
  height: 24px;
  line-height: 24px;
}
#app .el-input--small .el-input__inner {
  height: 32px;
  line-height: 32px;
}
#app .el-button--info {
  color: #fff;
  background: #f5f5f5;
  border-color: #f5f5f5;
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
  color: #666666;
  font-family: PingFangSC-Regular;
}
#app .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}
#app .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::after {
  content: '*';
  color: #c55252;
  margin-left: 4px;
}
#app .el-button {
  font-weight: 400;
}

#app :focus {
  outline: unset;
}

#app .el-table {
  &.el-table__body {
    padding-bottom: 36px;
  }
  & .cell {
    padding-top: 1px;
    line-height: 35px;
  }
  & td {
    height: 40px !important;
    padding: 0;
  }
}
.icon-back {
  color: #0b71bb;
  font-weight: bold;
}
.icon-back:hover {
  color: rgba(7, 82, 137, 1);
}
.icon-back:focus {
  color: rgba(7, 82, 137, 0.8);
}
.icon-move {
  vertical-align: 1px;
}
.el-table .el-table__body-wrapper {
  overflow-y: auto;
}
.el-table__body {
  padding-bottom: 40px;
}
.pagination {
  text-align: center;
  position: relative;
}
.pagination button {
  line-height: unset;
}
.table-column-time {
  width: 180px;
}
.pagination :before {
  content: '""';
  height: 36px;
  width: percent(100);
  position: absolute;
  display: block;
  top: -33px;
  left: 0;
  background: linearGradient(rgba(255, 255, 255, 0.2), #fff);
}
.icon-back {
  margin-right: 16px;
}
#app h1 {
  // chrome浏览器会用一个奇怪的样式覆盖h1, 导致h1字体比h2都小
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.circle-state {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  float: left;
  margin-top: 4px;
  margin-right: 7px;
}
.circle-overtime {
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
}
.circle-usable {
  background: #35b053;
  border: 1px solid #35b053;
}
.circle-disable {
  background: #c55252;
  border: 1px solid #c55252;
}
#app .main-area {
  border-radius: 4px;
}
.el-tooltip__popper.is-dark {
  max-width: 800px;
  line-height: 20px;
}
.el-tooltip__popper .popper__arrow {
    border-top-color: #333 !important;
}
.el-tooltip__popper .popper__arrow::after {
    border-top-color: #333 !important;
}

#app {
  //解决safari表格单元overflowTooltip问题
  body .el-table th.gutter {
    display: table-cell !important;
  }
  body .el-table colgroup.gutter {
    display: table-cell !important;
  }
  table {
    width: 100% !important;
  }
  .el-table__body {
    // 使表格兼容safari，不错位
    width: 100%;
    table-layout: fixed !important;
  }
  .f1aj5uep>div:last-child {
    width: auto;
  }
}
</style>
