<template>
  <div class="all">
    <div class="main">
      <div class="header container">
        <div class="logo"></div>
      </div>
      <div class="container context">
        <div class="bg-img"></div>
        <router-view></router-view>
      </div>
    </div>
    <!-- <el-footer class="footer">
      <span>Version: SafeLine SL-S20</span>
      <span>Copyright ©️ 2014 - 2021 China Tech. All Rights Reserbed. 长亭科技 版权所有</span>
    </el-footer> -->
  </div>
</template>

<style scoped>
.all {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.main {
  background-image: url('../../assets/login_background.png');
  background-size: 100% auto;
  background-position: center center;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
}
.footer {
  height: 60px;
  width: 100%;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #c1c1c1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px dashed #e5e5e5;
}
.header {
  height: 76px;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: auto;
}
.context {
  flex-grow: 2;
}
.logo {
  background-image: url(../../assets/logo_white.svg);
  background-repeat: no-repeat;
  background-size: 95%;
  background-position: center center;
  overflow: auto;
  height: 25px;
  width: 180px;
}

.aboutProduce a {
  margin-top: 30px;
  height: 25px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  margin-left: 40px;
}

.bg-img {
  background: url('../../assets/block.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: auto;
  float: left;
  width: 726px;
  height: 445px;
  position: relative;
  top: -80px;
}
</style>

<script>
export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    }
  },
}
</script>
