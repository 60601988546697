var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PackageHeader',[_c('Table',{ref:"table",staticClass:"table-list-scroll table-list-flex pt16 table-have-choice table-outline",attrs:{"border":true,"table-key":'package',"hide-selection":true,"value":_vm.tableData,"operations":[
           {
             icon: 'chaitin-iconfont icon-group',
             callback: _vm.handleRowClick,
             tooltip: '详情',
           },
           {
             icon: 'chaitin-iconfont icon-download',
             callback: _vm.handleDownload,
             tooltip: '下载',
           } ],"row-key":function (row) { return row.package_id; },"custom-column-options":_vm.columnOptions,"selected-columns":_vm.selectedColumn,"order":_vm.$route.query.order,"order-by":_vm.$route.query.orderBy},on:{"columnsChanged":_vm.handleSelectedChanged,"sortBy":_vm.handleSortBy}},[(_vm.selectedColumn.some(function (v) { return v === 'level'; }))?_c('TableColumn',{attrs:{"coefficient":0.6,"prop":"level","label":"级别"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:{
          'tag-common': scope.row.level === 'ordinary',
          'tag-danger': scope.row.level === 'important',
        }},[_vm._v(" "+_vm._s(_vm.packageLevel(scope.row.level))+" ")])]}}],null,false,3868682219)}):_vm._e(),(_vm.selectedColumn.some(function (v) { return v === 'module'; }))?_c('TableColumn',{attrs:{"coefficient":0.8,"prop":"module","label":"模块名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.packageModuleFormatter(_vm.product, scope.row)))])]}}],null,false,3889995118)}):_vm._e(),(_vm.selectedColumn.some(function (v) { return v === 'version'; }))?_c('TableColumn',{attrs:{"coefficient":1.0,"show-overflow-tooltip":true,"prop":"version","label":"版本号"}}):_vm._e(),(_vm.selectedColumn.some(function (v) { return v === 'published_time'; }))?_c('TableColumn',{attrs:{"coefficient":0.8,"prop":"published_time","label":"发布时间","class-name":"time-font","sortable":"custom"}}):_vm._e(),_c('i',{attrs:{"slot":"pagination"},slot:"pagination"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }