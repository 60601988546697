var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-page"},[_c('el-header',{staticClass:"main-head"},[_c('img',{staticClass:"horn-icon",attrs:{"src":require("../assets/horn.svg"),"alt":""}}),_vm._v(" 消息通知 "),_c('div',{staticClass:"flex-right message-receive no-hr"},[_c('Zone'),_c('Button',{staticClass:"message-receive-btn title-button",attrs:{"small":true,"type":"primary"},on:{"click":_vm.addReceive}},[_vm._v("消息接收配置")])],1)]),_c('FilterResultPanel',{staticClass:"filterClassName",attrs:{"slot":"prepend","key-storage":_vm.$route.name},slot:"prepend"}),_c('List',{staticClass:"table-list-model-scroll pt16",attrs:{"table-border":true,"table-key":'message',"selected":_vm.selected,"selected-all":_vm.selectedAll,"allow-select-all":"","operations":[
      {
        icon: 'chaitin-iconfont icon-group',
        callback: _vm.messageDetail,
        tooltip: '详情',
      } ],"zone-operations":[
      {
        label: '标记为已读',
        callback: _vm.handleReadAll,
      },
      {
        label: '删除',
        callback: _vm.handleDeleteAll,
      } ],"row-key":function (row) { return row.id; },"value":_vm.messageData},on:{"selectedChanged":_vm.handleSelectedChanged}},[_c('TableColumn',{attrs:{"coefficient":1.6,"label":"消息标题","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:{ realize: !scope.row.unread }},[_vm._v(_vm._s(scope.row.verb)+" ")])]}}])}),_c('TableColumn',{attrs:{"coefficient":0.8,"label":"消息类型","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:{ realize: !scope.row.unread }},[_vm._v(_vm._s(scope.row.type_name))])]}}])}),_c('TableColumn',{attrs:{"coefficient":0.8,"show-overflow-tooltip":true,"label":"发送时间","class-name":"time-font","prop":"created_time","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:{ realize: !scope.row.unread }},[_vm._v(_vm._s(scope.row.created_time)+" ")])]}}])}),_c('i',{attrs:{"slot":"pagination"},slot:"pagination"})],1),_c('PaginationContainer',{staticClass:"page-shadow",attrs:{"style-type":_vm.PaginationStyleType.Normal,"border":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }