var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-page"},[_c('el-header',{staticClass:"main-head"},[_c('img',{staticClass:"user-icon",attrs:{"src":require("../assets/user-info_icon.svg"),"alt":""}}),_vm._v(" 用户管理 "),_c('div',{staticClass:"flex-right"},[_c('Button',{staticClass:"title-button",attrs:{"small":true,"type":"primary"},on:{"click":_vm.addUser}},[_vm._v("添加用户")])],1)]),_c('FilterResultPanel',{staticClass:"filterClassName",attrs:{"slot":"prepend","key-storage":_vm.$route.name},slot:"prepend"}),_c('div',{staticClass:"table-box"},[_c('Table',{ref:"table",staticClass:"table-list-scroll table-list-flex pt16  table-have-choice",attrs:{"border":true,"table-key":'adminUser',"hide-selection":true,"size":"mini","value":_vm.tableData,"header-cell-style":{ background: '#F5F5F5' },"row-key":function (row) { return row.id; },"operations":[
             {
               icon: 'chaitin-iconfont icon-group',
               callback: function (row) { return _vm.$router.push({
                   name: 'AdminUserDetail',
                   query: { id: row.id },
                 }); },
               tooltip: '详情',
             } ],"custom-column-options":_vm.columnOptions,"selected-columns":_vm.selectedColumn,"order":_vm.$route.query.order,"order-by":_vm.$route.query.orderBy},on:{"columnsChanged":_vm.handleSelectedChanged,"sortBy":_vm.handleSortBy}},[(_vm.selectedColumn.some(function (v) { return v === 'username'; }))?_c('TableColumn',{attrs:{"coefficient":0.8,"prop":"username","label":"用户名"}}):_vm._e(),(_vm.selectedColumn.some(function (v) { return v === 'client_name'; }))?_c('TableColumn',{attrs:{"coefficient":1.4,"show-overflow-tooltip":true,"label":"授权企业"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.client_name)?[_vm._v(_vm._s(scope.row.client_name))]:_c('Empty')]}}],null,false,2566923671)}):_vm._e(),(_vm.selectedColumn.some(function (v) { return v === 'role'; }))?_c('TableColumn',{attrs:{"coefficient":0.8,"prop":"role","label":"用户身份","formatter":function (row) {
                     if (row.role == 'admin') {
                       return '管理员'
                     } else if (row.role == 'user') {
                       return '普通用户'
                     } else {
                       return '技术支持'
                     }
                   }}}):_vm._e(),(_vm.selectedColumn.some(function (v) { return v === 'email'; }))?_c('TableColumn',{attrs:{"coefficient":1.1,"prop":"email","label":"邮箱"}}):_vm._e(),(_vm.selectedColumn.some(function (v) { return v === 'mobile'; }))?_c('TableColumn',{attrs:{"coefficient":0.8,"prop":"mobile","label":"手机号"}}):_vm._e(),(_vm.selectedColumn.some(function (v) { return v === 'date_joined'; }))?_c('TableColumn',{attrs:{"coefficient":1.1,"show-overflow-tooltip":true,"prop":"date_joined","label":"注册时间","class-name":"time-font","sortable":"custom"}}):_vm._e(),(_vm.selectedColumn.some(function (v) { return v === 'last_login'; }))?_c('TableColumn',{attrs:{"coefficient":1.1,"prop":"last_login","show-overflow-tooltip":true,"sortable":"custom","label":"最后登录时间","class-name":"time-font"}}):_vm._e(),(_vm.selectedColumn.some(function (v) { return v === 'state'; }))?_c('TableColumn',{attrs:{"coefficient":0.8,"prop":"state","label":"用户状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"circle-state",class:{
            'circle-overtime': scope.row.state === 'overtime',
            'circle-usable': scope.row.state === 'usable',
            'circle-disable': scope.row.state === 'disable',
          }}),_c('span',[_vm._v(" "+_vm._s(_vm.userStateFormatter(scope.row)))])]}}],null,false,3658337885)}):_vm._e(),_c('i',{attrs:{"slot":"pagination"},slot:"pagination"})],1)],1),_c('PaginationContainer',{staticClass:"page-shadow",attrs:{"style-type":_vm.PaginationStyleType.Normal,"border":false}}),_c('Dialog',{attrs:{"value":_vm.showDialog,"title":"添加用户"},on:{"ok":_vm.handleAddUserOk,"input":function (v) { return (_vm.showDialog = v); },"cancel":_vm.handleAddUserCancel}},[_c('div',[_c('Form',{ref:"formName",attrs:{"form-model":_vm.userData}},[_c('FormItem',{attrs:{"prop":"userName","label":"用户名","rule":_vm.userNameRules}},[_c('TagSelect',{ref:"tagSelect",attrs:{"id":"tagSelectGroup","filter-only":true,"value":_vm.userData.userName,"options":_vm.optionsItem},on:{"input":function (v) {
                       _vm.userData.userName = v
                     },"add":function (v) {
                       _vm.userData.userName.push({ label: v, value: v })
                     }},model:{value:(_vm.userData.userName),callback:function ($$v) {_vm.$set(_vm.userData, "userName", $$v)},expression:"userData.userName"}})],1),_c('FormItem',{attrs:{"prop":"userCard","label":"用户身份","rule":_vm.userIdentityRules}},[_c('RadioGroup',{attrs:{"value":_vm.userData.userCard},on:{"input":function (v) { return (_vm.userData.userCard = v); }}},[_c('Radio',{attrs:{"index":"supporter","label":"技术支持","border":""}}),_c('Radio',{attrs:{"index":"admin","label":"管理员","border":""}})],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }